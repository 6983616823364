@import "scss/colors";

.group {
  .groupOwner {
    font-size: 20px;
    color: $primary-color;
    margin-right: 2px;
  }
}

.group-settings-modal {
  .group-link {
    margin: 5px 0
  }
}