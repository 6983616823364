@import "scss/colors";

.communities {
  .ant-table-row:hover {
    background-color: #f5f5f5;
  }
  .active-subscription {
    transition: background-color 0.5s ease-in-out;
    border-radius: 15px;

    &:hover {
      background-color: #b9b5ff;
    }
  }
}

.community-modal {
  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  .description {
    margin-bottom: 2ex;
  }

  &.active-subscription {
    .ant-modal-content {
      border: 2px solid $primary-color;
    }
  }
}
