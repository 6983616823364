.server-table {
  .server-table-row {
    cursor: pointer;
  }

  .version-icon {
    font-size: 16px;
    margin-right: 5px;

    &.red {
      color: red;
    }
    &.blue {
      color: blue;
    }
  }

  .table-actions {
    float: right;
    margin-right: 2ex;
  }
}

.server-details {
  .modal-header {
    font-size: 22px;
    border-bottom: 1px solid #d9d9d9b5;
    padding-bottom: 1ex;

    .server-name {
      font-size: 22px;
      display: inherit;
    }

    .server-icons {
      margin-left: 1ex;

      .anticon {
        margin-left: 10px;
        font-size: 25px;
      }
    }

    .description {
      font-size: 14px;
      margin-top: 5px;
      display: block;
      font-weight: normal;
    }

    .version {
      font-size: 14px;
      font-weight: normal;
    }

    .perma-btn {
      float: right;
      height: 0;
      padding: 0;
      margin-left: 2ex;

      span {
        color: black;
        font-size: 20px;
      }
    }
  }

  .details {
    ul {
      list-style: none;
      padding-left: 0;
    }

    .players {
      margin: 14px 0;
    }

    .name {
      font-size: 16px;
    }

    .sub-header {
      font-size: 14px;
      margin-left: 1ex;
      letter-spacing: 0.7px;
    }

    .modV {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.87);
      margin-left: 1ex;
    }

    .color-box {
      padding: 0 5px;
    }

    .borders {
      border-left: 1px solid rgba(5, 5, 5, 0.06);
      border-right: 1px solid rgba(5, 5, 5, 0.06);
    }
  }

  .loading-details {
    text-align: center;
    margin-top: 4ex;
  }
}
