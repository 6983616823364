.group-subscription {
  .group-types {
    margin-top: 2ex;
  }
  label {
    font-weight: 600;
    display: block;
    margin-bottom: 1ex;
  }

  .sub-btn {
    width: 100%;
    margin-top: 2ex;
  }

  .sub-status {

    &.canceled {
      color: red;
    }
  }
}

.change-subscription {
  .details {
    margin-left: 10px;
  }
}
