.tracked-server {
  .date-picker {
    margin-left: 4em;
    margin-bottom: 1em;
  }
}

.game-info {
  height: 100%;

  .players {
    > span {
      margin: 0;
      display: block;
    }

    a {
      padding: 0 5px;
    }
  }

  .icons svg {
    width: 2em;
    height: 2em;
  }
}

.heatmap {
  margin: 2em 0;

  h2 {
    display: inline;
  }

  .heatmap-year {
    margin-left: 2ex;
  }

  .spinner {
    text-align: center;
  }

  .cal-wrapper {
    height: 300px;
    width: 100%;
  }

  .header {
    padding: 7px 0px;
    margin-bottom: 5px;
    border-bottom: 1px solid #f0f0f0;

    span {
      display: block;
    }
  }

  .players {
    margin-bottom: 1ex;

    > span {
      display: block;
    }
  }

  .heatmap-selection {
    border: 1px solid #f0f0f0;
    border-radius: 15px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 10px;
  }

  .no-data {
    margin-top: 2em;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 15px;
    text-align: center;
    padding: 4ex;
    font-size: 16px;
    font-style: oblique;
  }
}
