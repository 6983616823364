.site-info {
  p,
  a {
    word-wrap: "break-word";
    font-size: 1rem;
  }

  .support {
    margin-bottom: 2ex;
  }
}

.privacy-policy {
  .rofl {
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
  }
}