@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "scss/colors";

html,
body,
#root,
.App {
  height: 100vh;
  margin: 0;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

h1 {
  font-size: xx-large;
  margin: 1ex 0;
  font-weight: 400;
}

h2 {
  font-size: x-large;
  margin-top: 0;
  margin-bottom: 0;
}

h4 {
  margin-bottom: 1ex;
}

b {
  font-weight: 600;
}

p {
  line-height: 22px
}

.err {
  color: red;
}

.ant-breadcrumb a {
  color: $primary-color;
}

section.page {
  margin-bottom: 2em;

  .content,
  &.content {
    padding: 0 2ex;
  }
}

.page-header {
  .action-btns {
    margin: 1ex 0;
    display: flex;
    justify-content: right;
    align-items: center;

    button {
      margin-left: 2ex;
    }
  }
}

.clickable {
  cursor: pointer;
}

.page404 {
  text-align: center;

  .text404 {
    font-weight: 600;
    font-size: 5rem;
  }

  .desc {
    font-size: medium;
  }

  img {
    max-height: 20rem;
  }

  small {
    display: block;
  }
}

.grey-space {
  background-color: #e0e0e0;
  padding: 1ex;
  border-radius: 15px;
  margin: 1ex 0;
}

.modal-footer {
  margin-top: 2ex;
    border-top: 1px solid #e0e0e0;
    padding-top: 2ex;
}