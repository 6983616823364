.login-account {
  margin-left: 4ex;

  .dst-icon {
    height: 64px;
  }

  .user-icon {
    cursor: pointer;
    font-size: 2em;
  }

  .dst-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    img {
      cursor: pointer;
      max-height: 55px;
    }
  }
}

.pop-up-box {
  position: absolute;
  border: 1px solid lightgray;
  background-color: white;
  line-height: normal;
  border-radius: 10px;
  right: 2ex;
  top: 60px;
  padding: 10px;
  text-align: center;

  box-shadow: -7px 7px 19px -6px rgba(0, 0, 0, 0.64);
  -webkit-box-shadow: -7px 7px 19px -6px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: -7px 7px 19px -6px rgba(0, 0, 0, 0.64);
}

// Pop up login/register
.login-account {
  .form {
    margin-top: 2ex;
  }

  p.title {
    margin: 1ex 0;
  }

  .form-element {
    margin: 0.5ex 0;
  }

  .options {
    margin-top: 2ex;
    display: block;

    span {
      cursor: pointer;
    }
  }
}

// Pop up user panel
.userPanel {
  .username {
    margin: 0;
    padding-bottom: 3px;
    font-size: 18px;
    border-bottom: 1px solid #cfcfcf;
    font-weight: 600;
  }

  .selected-group {
    text-align: left;
    margin: 2ex 0;

    .ant-select {
      margin-top: 3px;
    }
  }

  .group-invites {
    display: block;
    text-align: left;
    margin-top: 2ex;
    margin-bottom: 1ex;
    padding: 3px;

    .title {
      border-bottom: 1px solid #cfcfcf;
    }

    .invite {
      margin-top: 1ex;
      border: 1px solid rgb(182, 182, 182);
      border-radius: 10px;
      padding: 5px;

      .group-name {
        display: block;
        font-weight: 600;
      }

      .action-btns {
        text-align: center;
      }
      .accept {
        color: green;
      }
    }
  }

  .panel-footer {
    border-top: 1px solid #c8c8c8;
    padding-top: 10px;
  }
}
