// GroupTypeCard.tsx
.group-type-card {
  .select-group {
    cursor: pointer;
    -webkit-transition: border 300ms ease-out;
    -moz-transition: border 300ms ease-out;
    -o-transition: border 300ms ease-out;
    transition: border 300ms ease-out;

    &.group-selected {
      border-color: green;
    }
  }

  .token-group {
    .ant-card-head {
      background-color: #ffd300;

      .subHeader {
        font-style: italic;
        font-size: 12px;
      }
    }
  }

  .featured {
    color: #c5a915;
    font-size: 24px;
    float: right;
  }
}
