@import "scss/colors";
// Site Content CSS

// Header CSS
.site-header {
  padding: 0;
  background: #fff;
  display: flex;
  padding: 0 2em;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid rgb(153, 153, 153);

  .site-name {
    font-size: 32px;
    color: $primary-color;
    margin-right: 1ex;
  }
}

// Menu CSS
.menu-slider {
  position: fixed !important;
  height: 100vh;
  user-select: none;

  .ant-menu-item {
    border-radius: 0;
    margin: 0;
    width: 100%;
  }

  .trigger {
    padding: 0 24px;
    font-size: 25px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    width: 84px;
    height: 64px;
    color: #000000;

    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #000000;
    }
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
    font-size: 22px;
    margin-top: 9px; // To center the icons
  }
}

// Hide tooltip from collapsed menu
.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.revolt-icon {
  margin-left: auto;
}

.impersonation-bar {
  display: inline;

  button {
    margin-left: 1ex;
  }
}

.site-content {
  background-color: white;

  display: flex;
  flex-direction: column;
  min-height: 95vh;

  & > section {
    flex: 1;
  }

  footer.footer {
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    margin: 0 2em;
    padding: 2ex 0 0;

    .love {
      text-align: center;
    }

    .bottom-links {
      margin-top: 2ex;

      h4 {
        font-size: 14px;
        color: #747474;
      }

      a {
        color: gray;
      }

      ul {
        list-style: none;
        padding-left: 20px;
        margin: 0;

        li {
          padding: 3px;
        }
      }
    }
  }
}

.site-layout {
  transition: all 0.2s;
}
