@import "scss/colors";

.admin-settings {
  .action-btn {
    margin-bottom: 1em;
  }

  .update-modal {
    .ant-input,
    .ant-input-number,
    .ant-picker,
    .ant-select {
      margin: 3px 0;
    }

    .ant-checkbox-wrapper {
      margin-top: 2ex;
    }
  }

  .label {
    display: inline-block;
    color: $primary-color;
    margin-top: 5px;
  }

  .groupOwner {
    color: $primary-color;
  }
}
