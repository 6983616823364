.user-settings {
  .defaut-group {
    .ddl {
      margin-top: 2px;
      width: 90%;
    }

    small {
      display: block;
      padding: 2px 0 0 5px;
    }
  }

  .stripe-billing {
    margin-bottom: 2ex;
  }

  .verify-modal {
    transition: width 0.5s ease;

    .host-img {
      text-align: center;
      width: 100%;
      display: inline-block;
      margin-bottom: 2ex;
    }

    .server-name {
      width: 100%;
      display: block;
      text-align: center;
      font-size: 3.5rem;
      background-color: #e3e3e342;
      padding: .5ex 0;
      margin: 1ex 0;
      border-radius: 15px;
    }

    .start-verification,
    .verify {
      display: inline-block;
      margin-top: 2ex;
      text-align: end;
      width: 100%;
    }

    .instructions-img img {
      margin: 2ex;
      height: 80px;
    }

    .instructions-img2 {
      display: block;
      margin: 2ex;
    }

    .instructions-img3 {
      text-align: center;
      width: 100%;
      display: inline-block;

      img {
        margin: 2ex;
      }
    }

    // @include breakpoint(@screen-xs) {
    //   display: block;
    // }

  }
}

