.live-statistics {
  .bar-tooltip {
    border: 1px solid black;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    color: white;
    padding: 10px;

    .title {
      font-weight: 800;
      margin-bottom: 5px;
    }

    div {
      display: block;
    }
    span {
      font-weight: 600;
    }
  }

  .explanation {
    margin: 1ex
  }
}
